import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { Account, Order } from '@wilm/common';
import { PaymentMethodType } from '@wilm/common';
import Typography from 'components/commercetools-ui/atoms/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import useOrderData from '../../account/sections/orders/helper-hooks/useOrderData';

type ThankYouOrderInfoProps = {
    firstName?: Account['firstName'];
    lastName?: Account['lastName'];
    order?: Order;
};

const ThankYouOrderInfo: FC<ThankYouOrderInfoProps> = ({ firstName, lastName, order }) => {
    const { formatMessage } = useFormat({ name: 'thank-you' });
    const [loading, setLoading] = useState(true);
    const nameString = `${firstName ?? ''} ${lastName ?? ''},`;

    const { orderNumber, billingAddress, paymentInfo } = useOrderData(order);

    useEffect(() => {
        if (orderNumber && billingAddress) {
            setLoading(false);
        }
    }, [orderNumber, billingAddress, paymentInfo]);

    return (
        <div className="grid gap-8 border-b border-checkout-border-color py-24 md:gap-0 lg:py-26" data-cy="order-info">
            <div className="flex flex-wrap gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography className="w-fit text-md leading-6 text-secondary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {formatMessage({ id: 'order.number', defaultMessage: 'Order number' }) + ':'}
                    </Typography>
                </div>
                <Typography
                    className="order-info-width text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8"
                    asSkeleton={loading}
                >
                    {orderNumber ?? orderNumber}
                </Typography>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'delivery', defaultMessage: 'Delivery' }) + ':'}
                    </Typography>
                </div>
                <div>
                    <Typography
                        className="order-info-width contents text-md leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'dear', defaultMessage: 'Dear' })}
                    </Typography>
                    <Typography
                        className="order-info-width contents text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {` ${nameString} `}
                    </Typography>
                    <Typography
                        className="order-info-width contents text-md leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({
                            id: 'inform.you',
                            defaultMessage: 'we would like to inform you that some products might take a few days for provisioning.'
                        })}
                    </Typography>
                </div>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'payment', defaultMessage: 'Payment' }) + ':'}
                    </Typography>
                </div>
                <div className="flex flex-col gap-8 md:gap-0">
                    <Typography className="text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {paymentInfo.type === PaymentMethodType.CARD
                            ? formatMessage({ id: 'credit.card', defaultMessage: 'Credit/debit card' })
                            : ''}
                    </Typography>
                    <Typography
                        className="text-md font-bold capitalize leading-6 text-primary-black md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {`${paymentInfo.label ?? ''}`}
                    </Typography>
                </div>
            </div>
            <div className="flex gap-5 md:gap-0">
                <div className="w-120 md:w-136">
                    <Typography
                        className="w-120 text-md leading-6 text-secondary-black md:w-136 md:text-16 md:leading-8"
                        asSkeleton={loading}
                    >
                        {formatMessage({ id: 'billing.address', defaultMessage: 'Billing address' }) + ':'}
                    </Typography>
                </div>
                <div className="flex flex-col gap-8 md:gap-0">
                    <Typography className="text-md font-bold leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {nameString}
                    </Typography>
                    <Typography className="text-md leading-6 text-primary-black md:text-16 md:leading-8" asSkeleton={loading}>
                        {`${billingAddress ?? billingAddress}`}
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ThankYouOrderInfo;
